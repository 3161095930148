import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { classNames } from "primereact/utils";
import {
  parseTourItemBackLabel,
  parseTourItemNextLabel,
  parseTourItemTitle
} from "../utils/frigadeHelpers";
import { PeakaButton } from "@code2io/fe-studio-component-library";
import {
  faChevronLeft,
  faChevronRight,
  faXmark
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text, type FrigadeJS } from "@frigade/react";

export interface FrigadeTourItemOverlayPanelProps {
  backdropClassName?: string;
  className?: string;
  frigadeTourItemId: string;
  overlayClassName?: string;
  placeholder?: "bottom" | "left" | "right" | "top";
  target?: HTMLElement | string | null;
  targetActiveClassName?: string;
  targetAncestorActiveClassName?: string | ((element: HTMLElement) => string);
  step: FrigadeJS.FlowStep;
}

const FrigadeTourItemOverlayPanel = ({
  backdropClassName,
  className,
  frigadeTourItemId,
  overlayClassName,
  placeholder = "bottom",
  target,
  targetActiveClassName = "",
  targetAncestorActiveClassName = "",
  step
}: FrigadeTourItemOverlayPanelProps) => {
  const backdrop = useRef<HTMLDivElement>(null);
  const overlayPanel = useRef<OverlayPanel>(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const parsedBackLabel = parseTourItemBackLabel(step.secondaryButton?.title);
  const parsedNextLabel = parseTourItemNextLabel(step.primaryButton?.title);
  const parsedTitle = parseTourItemTitle(step.title);
  const [resizing, setResizing] = useState(false);

  const getTargetElement = useCallback(() => {
    if (target === undefined && parsedTitle.options.target == null) {
      return document.querySelector<HTMLElement>(
        `[data-onboarding="${frigadeTourItemId}"]`
      );
    }

    if (typeof target === "string") {
      return document.querySelector<HTMLElement>(target);
    }

    if (parsedTitle.options.target != null) {
      return document.querySelector<HTMLElement>(parsedTitle.options.target);
    }

    return target ?? null;
  }, [frigadeTourItemId, target, parsedTitle.options.target]);

  useEffect(() => {
    const check = () => {
      setTargetElement(getTargetElement());
    };

    const intervalId = window.setInterval(() => {
      check();
    }, 100);

    check();

    return () => {
      window.clearInterval(intervalId);
    };
  }, [getTargetElement]);

  useEffect(() => {
    if (targetElement == null) {
      return;
    }

    let timeoutId = -1;
    const listener = () => {
      setResizing(true);
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => setResizing(false), 1000);
    };

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.clearTimeout(timeoutId);
      setResizing(false);
    };
  }, []);

  useEffect(() => {
    if (targetElement != null && !resizing) {
      const overlayPanelCurrent = overlayPanel.current;
      overlayPanelCurrent?.show(
        { currentTarget: targetElement } as any,
        targetElement
      );

      // adds active classes to the target element
      const targetClassNames =
        classNames("frigade-tour-item-active-target", targetActiveClassName)
          ?.split(" ")
          .filter((className) => className !== "") ?? [];
      targetElement.classList.add(...targetClassNames);

      // adds active classes to all ancestors of the target element
      const ancestorElements: HTMLElement[] = [];
      const ancestorActiveClassNames: string[][] = [];
      let parentElement = targetElement.parentElement;
      while (parentElement != null) {
        const targetAncestorClassNames =
          classNames(
            typeof targetAncestorActiveClassName === "function"
              ? targetAncestorActiveClassName(parentElement)
              : targetAncestorActiveClassName
          )
            ?.split(" ")
            .filter((className) => className !== "") ?? [];

        ancestorElements.push(parentElement);
        ancestorActiveClassNames.push(targetAncestorClassNames);
        parentElement.classList.add(...targetAncestorClassNames);
        parentElement = parentElement.parentElement;
      }

      // cleans up
      return () => {
        overlayPanelCurrent?.hide();
        targetElement.classList.remove(...targetClassNames);
        ancestorElements.forEach((element, index) => {
          element.classList.remove(...ancestorActiveClassNames[index]);
        });
      };
    }
  }, [
    targetActiveClassName,
    targetAncestorActiveClassName,
    targetElement,
    resizing,
    step
  ]);

  useEffect(() => {
    if (targetElement == null && step.order > 0) {
      const id = setTimeout(() => {
        setResizing(true);
      }, 300);
      return () => clearTimeout(id);
    }
  }, [step.order, targetElement]);

  return (
    <>
      {!resizing &&
        createPortal(
          <div
            className={classNames(
              "frigade-tour-item-overlay",
              overlayClassName
            )}
          />,
          document.body
        )}

      {!resizing &&
        createPortal(
          <div
            className={classNames(
              "frigade-tour-item-backdrop",
              backdropClassName
            )}
            ref={backdrop}
          />,
          document.body
        )}

      <OverlayPanel
        appendTo={document.body}
        className={classNames(
          "frigade-tour-item-overlay-panel",
          placeholder,
          className
        )}
        dismissable={false}
        ref={overlayPanel}
        transitionOptions={{
          addEndListener: () => undefined,
          onEntering: () => {
            const element = overlayPanel.current?.getElement();
            const targetElement = getTargetElement();

            if (element != null && targetElement != null) {
              const targetBCR = targetElement.getBoundingClientRect();

              if (placeholder === "right") {
                element.style.top = `${targetBCR.top}px`;
                element.style.left = `${targetBCR.right}px`;
              }

              if (backdrop.current != null) {
                backdrop.current.style.top = `${targetBCR.top}px`;
                backdrop.current.style.left = `${targetBCR.left}px`;
                backdrop.current.style.right = `calc(100vw - ${targetBCR.right}px)`;
                backdrop.current.style.bottom = `calc(100vh - ${targetBCR.bottom}px)`;
              }
            }
          }
        }}
      >
        <div className="relative">
          <h6 className="mb-3 pr-3">{parsedTitle.value}</h6>

          <PeakaButton
            style={{
              top: "-4px",
              right: "0"
            }}
            className="absolute"
            icon={<FontAwesomeIcon icon={faXmark} />}
            onClick={async () => {
              await step.flow.skip();
            }}
            severity="secondary"
            ghost
          />
        </div>

        <div className="mb-3">
          <Text.Body2 className="pk-header-color">{step.subtitle}</Text.Body2>
        </div>
        <div className="flex align-items-center gap-2">
          {parsedBackLabel.originalValue != null && step.order !== 0 && (
            <PeakaButton
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={async () => {
                await step.flow.back();
              }}
              label={parsedBackLabel.value}
              severity="secondary"
              type="button"
            />
          )}
          {parsedNextLabel.originalValue != null && (
            <PeakaButton
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              iconPosition="right"
              onClick={async () => {
                if (step.flow.steps.size === step.order + 1) {
                  await step.flow.complete();
                } else {
                  await step.complete();
                }
              }}
              label={parsedNextLabel.value}
              type="button"
            />
          )}
        </div>
      </OverlayPanel>
    </>
  );
};

export default FrigadeTourItemOverlayPanel;
