import { classNames } from "primereact/utils";
import { useState } from "react";
import { PeakaButton } from "@code2io/fe-studio-component-library";
import moment from "moment";

export interface ApiKeyResultProps {
  apiKey: string;
  expiresAt: string;
  className?: string;
}

export const ApiKeyResultContent = ({
  apiKey,
  expiresAt,
  className
}: ApiKeyResultProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyText = async () => {
    await navigator.clipboard.writeText(apiKey);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  function renderCopyButton() {
    return isCopied ? (
      <div
        className="flex cursor-pointer"
        onClick={() => copyText().catch((err) => console.log(err))}
      >
        <span className="c2-icons-checked text-green-400 text-xl" />
      </div>
    ) : (
      <div
        className="flex cursor-pointer"
        onClick={() => copyText().catch((err) => console.log(err))}
      >
        <span className="c2-icons-copy-to-clipboard text-xl" />
      </div>
    );
  }

  return (
    <div
      className={classNames("flex flex-column text-sm", className)}
      style={{
        background: "var(--background-primary)"
      }}
    >
      <div className="flex-1">
        <div className="flex flex-column justify-content-center align-items-center">
          <div className="flex flex-column justify-content-center align-items-center mt-5 w-full">
            <span
              className={classNames(
                "api-key-result-success-icon mb-3",
                "fa fa-check-circle"
              )}
            />
            <div className={"api-key-result-success-header"}>
              API Key Created Successfully.
            </div>
            {expiresAt && (
              <div
                className={classNames(
                  "api-key-result-success-text w-full text-center"
                )}
              >
                <span> Expires {moment(expiresAt).fromNow()} </span>
              </div>
            )}

            <div className={classNames("api-key-result-success-sub-header")}>
              <span> Your Key</span>
            </div>
            <div className={"api-key-result-value-container"}>
              <span className={"api-key-result-value-text"}>{apiKey}</span>
              {renderCopyButton()}
            </div>
            <div className={classNames("api-key-result-success-text")}>
              <span> Please copy this key as it will be only seen once.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApiKeyResultFooter = ({ onDone }: { onDone: () => void }) => {
  return (
    <div className="flex align-items-center justify-content-end gap-3">
      <PeakaButton label="Done" onClick={() => onDone()} />
    </div>
  );
};
