import { useRef, useState } from "react";
import { classNames } from "primereact/utils";
import {
  type ApiKeyResultType,
  useDeleteApiKeyMutation,
  useActivateApiKeyMutation,
  useDeActivateApiKeyMutation
} from "../../app/services/apiKey";
import { Menu } from "primereact/menu";
import ApiKeyForm from "./ApiKeyForm";
import { BlockUI } from "primereact/blockui";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  PeakaButton,
  PeakaInputSwitch
} from "@code2io/fe-studio-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTriangleExclamation
} from "@fortawesome/pro-regular-svg-icons";
import PeakaDialog from "../../app/utils/peakaDialog";

export interface ApiKeyRowProps {
  appId: string;
  apiKey?: ApiKeyResultType;
  isHeaderRow?: boolean;
  index?: number;
}

const ApiKeyRow = ({
  appId,
  apiKey,
  isHeaderRow = false,
  index
}: ApiKeyRowProps) => {
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const menu = useRef<Menu | null>(null);
  const [activateApiKey] = useActivateApiKeyMutation();
  const [deactivateApiKey] = useDeActivateApiKeyMutation();
  const [blocked, setBlocked] = useState(false);
  const [editApiKeyDialogVisible, setEditApiKeyDialogVisible] = useState(false);
  const [deleteApiKeyDialogVisible, setDeleteApiKeyDialogVisible] =
    useState(false);

  if (isHeaderRow) {
    return (
      <>
        <BlockUI blocked={blocked} fullScreen />
        <div className={classNames("api-key-row-header-container")}>
          <div
            className={classNames(
              "api-key-row-header-text",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            Name
          </div>
          <div
            className={classNames(
              "api-key-row-header-text",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            Key
          </div>
          <div
            className={classNames(
              "api-key-row-header-text",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            Status
          </div>

          <div
            className={classNames(
              "api-key-row-header-text",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            Created At
          </div>
          <div
            className={classNames(
              "api-key-row-header-text",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            Expires At
          </div>
          <div
            className="flex justify-content-start align-items-center gap-1"
            style={{ visibility: "hidden" }}
          >
            <PeakaButton
              severity="secondary"
              size="sm"
              ghost
              icon={<FontAwesomeIcon icon={faEllipsis} />}
              onClick={(e) => {
                menu.current?.toggle(e);
              }}
            />

            <Menu
              appendTo={document.body}
              model={[
                {
                  icon: "fa-regular fa-pen",
                  command: () => {
                    setEditApiKeyDialogVisible(true);
                  },
                  label: "Edit Name"
                },
                {
                  icon: "fa-regular fa-trash",
                  command: () => {
                    setBlocked(true);
                    deleteApiKey({ id: apiKey!.id })
                      .then(() => {
                        setBlocked(false);
                      })
                      .catch(() => {
                        // nothing to do here
                      });
                  },
                  label: "Delete"
                }
              ]}
              popup
              ref={menu}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <BlockUI
          blocked={blocked}
          fullScreen
          template={<ProgressSpinner animationDuration=".5s" />}
        />
        <div
          className={classNames("api-key-row-container", {
            odd: index! % 2 !== 0,
            even: index! % 2 === 0
          })}
        >
          <div className={"api-key-name-column gap-1"}>{apiKey!.name}</div>
          <div
            className={classNames(
              "api-key-key-prefix-column",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            <span className="fa-regular fa-key" />
            {apiKey!.keyPrefix + "******"}
          </div>
          <div
            className={classNames(
              "api-key-key-status-column",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            <PeakaInputSwitch
              size="sm"
              checked={apiKey!.active}
              onChange={async () => {
                if (apiKey!.active) {
                  setBlocked(true);
                  await new Promise((resolve) => setTimeout(resolve, 200));
                  await deactivateApiKey({ id: apiKey!.id });
                  setBlocked(false);
                } else {
                  setBlocked(true);
                  await new Promise((resolve) => setTimeout(resolve, 200));
                  await activateApiKey({ id: apiKey!.id });
                  setBlocked(false);
                }
              }}
            />
            {apiKey!.active ? "Active" : "Inactive"}
          </div>
          <div
            className={classNames(
              "api-key-name-column",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            {moment(apiKey!.createdAt).format("MMM. D, YYYY")}
          </div>
          <div
            className={classNames(
              "api-key-name-column",
              "flex justify-content-start align-items-center gap-1"
            )}
          >
            {apiKey!.expiresAt
              ? moment(apiKey!.expiresAt).format("MMM. D, YYYY")
              : "No Expiration"}
          </div>

          <div className="flex justify-content-start align-items-center">
            <PeakaButton
              severity="secondary"
              size="sm"
              ghost
              icon={<FontAwesomeIcon icon={faEllipsis} />}
              onClick={(e) => {
                menu.current?.toggle(e);
              }}
            />

            <Menu
              appendTo={document.body}
              model={[
                {
                  icon: "fa-regular fa-pen",
                  command: () => {
                    setEditApiKeyDialogVisible(true);
                  },
                  label: "Edit Name"
                },
                {
                  icon: "fa-regular fa-trash",
                  command: () => {
                    setDeleteApiKeyDialogVisible(true);
                  },
                  label: "Delete"
                }
              ]}
              popup
              ref={menu}
            />

            <ApiKeyForm
              visible={editApiKeyDialogVisible}
              onHide={() => setEditApiKeyDialogVisible(false)}
              appId={appId}
              isGenerate={false}
              className="h-full w-full"
              onCancel={() => setEditApiKeyDialogVisible(false)}
              apiKey={apiKey}
            />

            <PeakaDialog
              size="sm"
              visible={deleteApiKeyDialogVisible}
              onHide={() => setDeleteApiKeyDialogVisible(false)}
              header="Delete API Key"
              footer={
                <div className="flex align-items-center justify-content-end gap-2">
                  <PeakaButton
                    className="ml-auto"
                    label="Cancel"
                    onClick={() => setDeleteApiKeyDialogVisible(false)}
                    severity="secondary"
                    outlined
                  />
                  <PeakaButton
                    label="Yes, Delete"
                    onClick={() => {
                      setBlocked(true);
                      deleteApiKey({ id: apiKey!.id })
                        .then(() => {
                          setBlocked(false);
                          setDeleteApiKeyDialogVisible(false);
                        })
                        .catch(() => {
                          // nothing to do here
                        });
                    }}
                    severity="destructive"
                  />
                </div>
              }
              content={
                <div className="flex align-items-center gap-3">
                  <FontAwesomeIcon
                    style={{ fontSize: "2.25rem" }}
                    icon={faTriangleExclamation}
                    className="text-red-400"
                  />
                  <div className="text-base flex flex-column">
                    <div>
                      Are you sure you want to delete this API Key? Any
                      applications or services using this key will lose access
                      to the API. This action cannot be undone.
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </>
    );
  }
};

export default ApiKeyRow;
