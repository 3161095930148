import type { Middleware } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import toast from "./toast/toast";
import { createElement } from "react";

export const getErrorMessage = (errCode: string): string | null => {
  switch (errCode) {
    case "sr001":
      return "An error occurred during signup. Please try again later. If the problem persists, do not hesitate to contact us.";
    case "sr002":
      return "Failed authentication during signup";
    case "sr003":
      return "It looks like you have already signed up for Peaka. You can log in with your credentials or Google account.";
    case "sr004":
      return "It looks like you haven't joined our private beta list yet. Please contact us to get access.";
    case "sr006":
      return "Recently deleted accounts can't sign up for 30 days. If you think this is a mistake, please contact us.";
    case "lr001":
      return "An error occurred during login. Please try again later. If the problem persists, do not hesitate to contact us.";
    case "lr002":
      return "Login failed. Please try again later. If the problem persists, do not hesitate to contact us.";
    case "lr003":
      return "It looks like you haven't signed up yet. Please sign up first.";
    case "lr004":
      return "It looks like you haven't joined our private beta list yet. Please contact us to get access.";
    case "lr007":
      return "Recently deleted accounts can't log in for 30 days. If you think this is a mistake, please contact us.";
    default:
      return null;
  }
};

export const checkIfConnectorError = (errorCode: number, message: string) => {
  const isJsonValid = (json: string) => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  const isErrorNumber = (errorCode: string | undefined) => {
    if (!errorCode) return false;

    return !isNaN(parseInt(errorCode));
  };

  const isJsonHasConnectorError = (message: string) => {
    if (!isJsonValid(message)) return false;

    const json = JSON.parse(message);
    if (json.error && json.message) return true;
    return false;
  };
  return errorCode === 100 && isJsonHasConnectorError(message);
};

const formatConnectorError = (message: string) => {
  const errorCode = message.split(".")[1];
  const connectorName = message.split(".")[0];
  switch (errorCode) {
    case "401":
    case "403":
      return `${connectorName.toUpperCase()} (${errorCode}): Authorization Error`;
    case "404":
      return `${connectorName.toUpperCase()} (${errorCode}): Not Found Error`;
    default:
      return `${connectorName.toUpperCase()}: ${errorCode ?? "Peaka"} Error`;
  }
};

const formatConnectorErrorDetail = (
  error: string,
  message: string,
  traceId: string
) => {
  const errorCode = error.split(".")[1];
  const product = error.split(".")[0];
  switch (errorCode) {
    case "401":
    case "403": {
      const messageElement = createElement(
        "div",
        {},
        traceId &&
          createElement(
            "p",
            { className: "font-bold" },
            `Trace ID: ${traceId}`
          ),
        createElement(
          "p",
          { className: "font-bold" },
          `Invalid credential or missing permission(s). Please check your ${product} account`
        ),
        isValidJSON(message)
          ? createElement(
              "pre",
              {
                style: {
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word"
                }
              },
              JSON.stringify(JSON.parse(message), null, 2)
            )
          : createElement(
              "pre",
              { style: { whiteSpace: "pre-wrap", wordWrap: "break-word" } },
              message
            )
      );
      return messageElement;
    }
    default: {
      const messageElement = createElement(
        "div",
        {},
        traceId &&
          createElement(
            "p",
            { className: "font-bold" },
            `Trace ID: ${traceId}`
          ),
        isValidJSON(message)
          ? createElement(
              "pre",
              {
                style: {
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word"
                }
              },
              JSON.stringify(JSON.parse(message), null, 2)
            )
          : createElement(
              "pre",
              { style: { whiteSpace: "pre-wrap", wordWrap: "break-word" } },
              message
            )
      );
      return messageElement;
    }
  }
};

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const rtkQueryErrorHandlerMiddleware: Middleware = () => (next) => (action) => {
  if (
    isRejectedWithValue(action) &&
    !isBlackListed(action.meta.arg.endpointName)
  ) {
    if (
      action.payload.data === null ||
      typeof action.payload.data === "undefined"
    ) {
      return next(action);
    }

    const { errorCode, message, error, traceId } = action.payload.data;

    let err = errorCode;

    if (typeof errorCode === "number") err = errorCode.toString();
    const upgradeElement = createElement(
      "div",
      {},
      createElement(
        "p",
        {},
        "Oops! You've reached the 2-connector limit for your project. Time to decide:"
      ),
      createElement("p", {}, "* Remove old connectors to add new ones."),
      createElement(
        "p",
        {},
        "* OR ",
        createElement("a", { href: "/home/billing" }, "Upgrade your plan"),
        " for unlimited connector additions."
      )
    );
    if (checkIfConnectorError(errorCode, message)) {
      toast.current?.show({
        severity: "error",
        summary: formatConnectorError(JSON.parse(message).error),
        detail: formatConnectorErrorDetail(
          JSON.parse(message).error,
          JSON.parse(message).message,
          JSON.parse(message).traceId
        ),
        life: 10000,
        closable: true
      });
    } else if (!errorCode && error && message) {
      toast.current?.show({
        severity: "error",
        summary: error,
        detail:
          message === "Free account catalog size has been reached"
            ? upgradeElement
            : createElement(
                "div",
                {},
                traceId && createElement("p", {}, traceId),
                createElement("p", {}, message)
              ),
        life: 10000,
        closable: true
      });
    } else if (err) {
      const errorMessage = getErrorMessage(err) ?? (message as string);

      toast.current?.show({
        severity: "error",
        summary: `Error: ${(err as string).toUpperCase()}`,
        detail:
          errorMessage === "Free account catalog size has been reached"
            ? upgradeElement
            : createElement(
                "div",
                {},
                traceId && createElement("p", {}, traceId),
                createElement("p", {}, message)
              ),
        life: 10000,
        closable: true
      });
    }
  }

  return next(action);
};

export default rtkQueryErrorHandlerMiddleware;

// Black List Implementation
// ============================================================

const blackListedEndpointNames: string[] = [];

function isBlackListed(endpointName: string) {
  return blackListedEndpointNames.includes(endpointName);
}

export const blackListEndpointNames = (...endpointNames: string[]) => {
  for (const endpointName of endpointNames) {
    if (!blackListedEndpointNames.includes(endpointName)) {
      blackListedEndpointNames.push(endpointName);
    }
  }
};
