import { classNames } from "primereact/utils";
import { Menu } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";
import { memo, useEffect, useRef, useState } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import type { IActionNodeData } from "../../types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectSelectedNode,
  setCopiedAction,
  setSelectedNode
} from "../../flowDesignerSlice";
import { CSSTransition } from "react-transition-group";
import "./AnimatedEntry.css";
import { useGetFlowDataQuery } from "../../../../app/services/collaboration/collaborationApi";
import { useFlowId } from "../../hooks/useFlowId";

const ReusableFlowNode = ({
  id,
  data: { label, onDelete, orientation }
}: NodeProps<IActionNodeData>) => {
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);
  const selected = useAppSelector(selectSelectedNode) === id;
  const dispatch = useAppDispatch();
  const selectedFlowId = useFlowId();
  const menu = useRef<Menu>(null);
  const { data } = useGetFlowDataQuery(selectedFlowId ?? "");

  const menuModel: MenuItem[] = [
    {
      icon: "pi pi-copy",
      label: "Copy",
      command: (event) => {
        event.originalEvent.stopPropagation();
        dispatch(setCopiedAction(event.item.data ?? null));
      },
      data: data?.draft.nodes[id] ?? null
    },
    {
      icon: "pi pi-trash",
      label: "Delete",
      command: (event) => {
        event.originalEvent.stopPropagation();
        onDelete(id);
      }
    }
  ];

  useEffect(() => {
    setInProp(true);
  }, []);

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{ visibility: "hidden" }}
      />
      <CSSTransition
        nodeRef={nodeRef}
        timeout={500}
        in={inProp}
        classNames="animated-entry-node"
      >
        <div
          ref={nodeRef}
          onClick={() => dispatch(setSelectedNode(id))}
          className="w-full h-full relative cursor-pointer"
        >
          <div
            className={classNames(`reusable-flow-node`, {
              active: selected
            })}
          >
            <div className="flex gap-1 align-items-center">{label}</div>
            <div className="flex align-items-center">
              <Menu popup ref={menu} model={menuModel} />
              <i
                className="pi pi-ellipsis-h text-xs"
                onClick={(event) => {
                  event.stopPropagation();
                  menu.current?.toggle(event);
                }}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
      <Handle
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

export default memo(ReusableFlowNode);
