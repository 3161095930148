export const EnvironmentConstants = {
  ZIPY_API_KEY: "ZIPY_API_KEY",
  GTM_API_KEY: "GTM_API_KEY",
  GA_API_KEY: "GA_API_KEY",
  STUDIO_API_URL: "STUDIO_API_URL",
  COLLABORATION_BACKEND_ADDRESS: "COLLABORATION_BACKEND_ADDRESS",
  ENVIRONMENT: "ENVIRONMENT",
  GENERATE_SOURCEMAP: "GENERATE_SOURCEMAP",
  CONTACT_US_API_URL: "CONTACT_US_API_URL",
  ZIPY_RELEASE_VERSION: "ZIPY_RELEASE_VERSION",
  SOCIAL_LOGIN_ENABLED: "SOCIAL_LOGIN_ENABLED",
  PAYMENT_ENABLED: "PAYMENT_ENABLED",
  ZIPY_ENABLED: "ZIPY_ENABLED",
  HYPERDX_ENABLED: "HYPERDX_ENABLED",
  HYPERDX_API_KEY: "HYPERDX_API_KEY",
  ZONE: "ZONE",
  FLOW_HISTORY_BASE_URL: "FLOW_HISTORY_BASE_URL",
  GA_ENABLED: "GA_ENABLED",
  ONPREMISE: "ONPREMISE",
  MIXPANEL_ENABLED: "MIXPANEL_ENABLED",
  POSTHOG_HOST: "POSTHOG_HOST",
  POSTHOG_KEY: "POSTHOG_KEY",
  POSTHOG_ENABLED: "POSTHOG_ENABLED",
  NAT_IP: "NAT_IP"
};

export const AppConstants = {
  SESSION_STORAGE_KEYS: {
    SOCIAL_AUTH: "SOCIAL_AUTH"
  },
  GOOGLE_LOGIN: "GOOGLE_LOGIN",
  GOOGLE_SIGNUP: "GOOGLE_SIGNUP",
  PASSWORD_LOGIN: "PASSWORD_LOGIN",
  PASSWORD_SIGNUP: "PASSWORD_SIGNUP"
};
