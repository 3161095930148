import { Menu } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";
import { classNames } from "primereact/utils";
import { memo, useRef } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import Icon from "../../../../app/components/Icon";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSelectedNode, setSelectedNode } from "../../flowDesignerSlice";
import type { ISwitchNodeData } from "../../types";
import { tooltipContent } from "../../data";
import { PeakaTooltip } from "@code2io/fe-studio-component-library";

const SwitchNode = ({
  id,
  data: { onDelete, orientation }
}: NodeProps<ISwitchNodeData>) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectSelectedNode) === id;
  const nodeRef = useRef<HTMLDivElement>(null);

  const menu = useRef<Menu>(null);

  const menuModel: MenuItem[] = [
    {
      icon: "pi pi-trash",
      label: "Delete",
      command: (event) => {
        event.originalEvent.stopPropagation();
        onDelete(id);
      }
    }
  ];

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <PeakaTooltip
        position="top"
        text={tooltipContent.switch}
        size="md"
        target={nodeRef.current ?? undefined}
        showDelay={500}
      />
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{
          visibility: "hidden"
        }}
      />
      <div
        className={classNames("node-container", {
          active: selected
        })}
        ref={nodeRef}
        onClick={() => dispatch(setSelectedNode(id))}
      >
        <div className="switch-node-indicator" />
        <div className="flex w-full px-2 justify-content-between">
          <div className="flex gap-1 align-items-center">
            <Icon className="text-2xl" icon="flow" />
            Switch
          </div>
          <div className="flex align-items-center">
            <Menu popup ref={menu} model={menuModel} />
            <i
              className="pi pi-ellipsis-h text-xs"
              onClick={(event) => {
                event.stopPropagation();
                menu.current?.toggle(event);
              }}
            />
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{
          visibility: "hidden"
        }}
      />
    </>
  );
};

export default memo(SwitchNode);
