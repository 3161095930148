import { memo, useEffect, useRef, useState } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import type { BackendTriggerNodeData } from "../../types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSelectedNode, setSelectedNode } from "../../flowDesignerSlice";
import { CSSTransition } from "react-transition-group";
import "./AnimatedEntry.css";
import { classNames } from "primereact/utils";

const BackendTriggerNode = ({
  id,
  data: { type, orientation }
}: NodeProps<BackendTriggerNodeData>) => {
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);
  const label = type === "webhook" ? "Webhook Trigger" : "Scheduled Trigger";
  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectSelectedNode) === id;

  useEffect(() => {
    setInProp(true);
  }, []);

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{ visibility: "hidden" }}
      />
      <CSSTransition
        nodeRef={nodeRef}
        timeout={500}
        in={inProp}
        classNames="animated-entry-node"
      >
        <div
          ref={nodeRef}
          onClick={() => dispatch(setSelectedNode(id))}
          className="w-full h-full"
        >
          <div
            className={classNames("node-container", {
              active: selected
            })}
          >
            <div className="action-node-indicator" />
            <div className="flex w-full align-items-center justify-content-center">
              {label}
            </div>
          </div>
        </div>
      </CSSTransition>
      <Handle
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

export default memo(BackendTriggerNode);
