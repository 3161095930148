import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";

const ApplicationsSkeleton = () => {
  return (
    <div
      style={{ padding: "1.75rem" }}
      className={classNames(
        "m-6 w-full border-round-3xl pk-bg-secondary h-25rem"
      )}
    >
      <div className="w-full flex align-items-center justify-content-between mb-4">
        <Skeleton className="h-2rem w-12rem" />
        <Skeleton className="h-2rem w-11rem" />
      </div>
      <div className="w-full h-full flex gap-3">
        <Skeleton className="flex-1 h-16rem" />
        <Skeleton className="flex-1 h-16rem" />
        <Skeleton className="flex-1 h-16rem" />
      </div>
    </div>
  );
};

export default ApplicationsSkeleton;
