export const allowedProperties = [
  "--absolute-white",
  "--primary-base",
  "--primary-hover",
  "--primary-dark",
  "--primary-light",
  "--primary-lighter",
  "--secondary-base",
  "--secondary-hover",
  "--secondary-dark",
  "--secondary-light",
  "--secondary-lighter",
  "--information-base",
  "--information-hover",
  "--information-dark",
  "--information-light",
  "--information-lighter",
  "--warning-base",
  "--warning-hover",
  "--warning-dark",
  "--warning-light",
  "--warning-lighter",
  "--error-base",
  "--error-hover",
  "--error-dark",
  "--error-light",
  "--error-lighter",
  "--success-base",
  "--success-hover",
  "--success-dark",
  "--success-light",
  "--success-lighter",
  "--neutral-base",
  "--neutral-hover",
  "--neutral-dark",
  "--neutral-light",
  "--neutral-lighter",
  "--updating-base",
  "--updating-hover",
  "--updating-dark",
  "--updating-light",
  "--updating-lighter",
  "--link-text-color",
  "--link-text-hover-color",
  "--header-color",
  "--text-primary-color",
  "--text-secondary-color",
  "--text-label-color",
  "--text-disabled-color",
  "--text-placeholder-color",
  "--border-active-color",
  "--border-primary-strong-color",
  "--border-primary-color",
  "--border-strong-color",
  "--border-soft-color",
  "--border-secondary-color",
  "--border-soft-secondary-color",
  "--border-error-color",
  "--border-success-color",
  "--border-warning-color",
  "--border-information-color",
  "--border-neutral-color",
  "--background-default",
  "--background-primary",
  "--background-secondary",
  "--background-tertiary",
  "--background-offline",
  "--text-color",
  "--text-color-secondary",
  "--primary-color-text",
  "--font-family",
  "--size-sm",
  "--size-md",
  "--size-lg",
  "--size-xl",
  "--padding-none",
  "--padding-3xs",
  "--padding-2xs",
  "--padding-xs",
  "--padding-sm",
  "--padding-md",
  "--padding-lg",
  "--padding-xl",
  "--padding-2xl",
  "--padding-3xl",
  "--padding-4xl",
  "--padding-5xl",
  "--radius-xs",
  "--radius-sm",
  "--radius-md",
  "--radius-lg",
  "--radius-xl",
  "--radius-2xl",
  "--radius-3xl",
  "--heading-xxs-size",
  "--heading-xs-size",
  "--heading-sm-size",
  "--heading-md-size",
  "--heading-lg-size",
  "--heading-xl-size",
  "--heading-xxl-size",
  "--heading-xxs-line-height",
  "--heading-xs-line-height",
  "--heading-sm-line-height",
  "--heading-md-line-height",
  "--heading-lg-line-height",
  "--heading-xl-line-height",
  "--heading-xxl-line-height",
  "--label-xs-size",
  "--label-sm-size",
  "--label-md-size",
  "--label-lg-size",
  "--label-xl-size",
  "--label-xs-line-height",
  "--label-sm-line-height",
  "--label-md-line-height",
  "--label-lg-line-height",
  "--label-xl-line-height",
  "--body-xs-size",
  "--body-sm-size",
  "--body-md-size",
  "--body-lg-size",
  "--body-xl-size",
  "--body-xs-line-height",
  "--body-sm-line-height",
  "--body-md-line-height",
  "--body-lg-line-height",
  "--body-xl-line-height",
  "--font-thin",
  "--font-extralight",
  "--font-light",
  "--font-normal",
  "--font-medium",
  "--font-semibold",
  "--font-bold",
  "--font-extrabold",
  "--font-black",
  "--shadow-sm",
  "--shadow-md",
  "--shadow-lg",
  "--shadow-xl",
  "--tooltip-shadow",
  "--input-shadow",
  "--avatar-shadow",
  "--border-shadow",
  "--gradient-01",
  "--gradient-02",
  "--gradient-03",
  "--gradient-04",
  "--gradient-05",
  "--gradient-06",
  "--gradient-07",
  "--gradient-08",
  "--gradient-09",
  "--gradient-10"
];
