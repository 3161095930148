import { allowedProperties } from "./customCssConstants";

export const loadSafeCss = async (url?: string, cssString?: string) => {
  if (!url && !cssString) throw new Error("Please provide a source");
  let cssText;
  if (url) {
    const response = await fetch(url);

    if (!response.ok) throw new Error("CSS dosyası yüklenemedi!");

    cssText = await response.text();
  }
  if (cssString) {
    cssText = cssString;
  }

  if (!cssText) throw new Error("There is a problem with css source");

  const withoutComments = cssText.replace(/\/\*[\s\S]*?\*\//g, "").trim();

  const isValid = withoutComments.split(";").every((line) => {
    const match = line.match(/^\s*([\w-]+)\s*:/);
    if (!match) return true;

    const property = match[1].trim();
    return allowedProperties.includes(property);
  });

  //if (!isValid) throw new Error("CSS dosyası geçersiz property'ler içeriyor!");

  if (isValid && url) {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = url;
    document.head.prepend(link);
  } else if (isValid && cssString) {
    const style = document.createElement("style");
    style.textContent = cssString;
    document.head.prepend(style);
  }
};

export const isCssFileSafe = (cssString: string) => {
  const withoutComments = cssString.replace(/\/\*[\s\S]*?\*\//g, "").trim();
  const match = withoutComments.match(/^\*\s*\{\s*([^}]+)\s*\}$/);

  if (!match) {
    return { isValid: false, invalidRules: ["Extra Content in CSS File"] }; // Eğer format "* { ... }" değilse, geçersiz say
  }

  // İçerideki kuralları ayıkla
  const rules = match[1]
    .split(";")
    .map((rule) => rule.trim())
    .filter(Boolean);

  // Her bir kuralın geçerli olup olmadığını kontrol et
  const isValid = rules.every((rule) => {
    const [property, value] = rule.split(":").map((part) => part.trim());
    console.log(
      "res",
      allowedProperties.includes(property) && value,
      property,
      value
    );
    return allowedProperties.includes(property) && value;
  });

  const invalidRules = rules.filter((rule) => {
    const [property, value] = rule.split(":").map((part) => part.trim());
    return !allowedProperties.includes(property) || !value;
  });

  return { isValid, invalidRules };
};
