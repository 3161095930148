import { classNames } from "primereact/utils";
import { Skeleton } from "primereact/skeleton";

const BillingSkeleton = () => {
  return (
    <div
      style={{ padding: "1.75rem" }}
      className={classNames("m-6 border-round-3xl h-full ml-6")}
    >
      <Skeleton className="w-full max-w-10rem h-2rem mb-2" />
      <Skeleton className="w-full h-1rem mb-4" />
      <div className="border-round-3xl p-4">
        <Skeleton className="h-10rem w-full mb-4" />
        <Skeleton className="h-2rem w-full max-w-20rem mb-2" />
        <Skeleton className="h-1rem w-full max-w-24rem mb-4" />

        <div className="w-full h-full flex gap-3">
          <Skeleton className="flex-1 h-10rem" />
          <Skeleton className="flex-1 h-10rem" />
          <Skeleton className="flex-1 h-10rem" />
        </div>
      </div>
    </div>
  );
};

export default BillingSkeleton;
