import { classNames } from "primereact/utils";
import { memo, useRef } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSelectedNode, setSelectedNode } from "../../flowDesignerSlice";
import type { ITriggerNodeData } from "../../types";
import { tooltipContent } from "../../data";
import { PeakaTooltip } from "@code2io/fe-studio-component-library";

const CallableNode = ({
  id,
  isConnectable,
  data: { orientation }
}: NodeProps<ITriggerNodeData>) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectSelectedNode) === id;
  const nodeRef = useRef<HTMLDivElement>(null);

  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <PeakaTooltip
        position="top"
        text={tooltipContent.callable}
        target={nodeRef.current ?? undefined}
        showDelay={500}
      />
      <div
        ref={nodeRef}
        className="w-full h-full relative cursor-pointer"
        onClick={() => dispatch(setSelectedNode(id))}
      >
        <div
          className={classNames("callable-node node-container", {
            active: selected
          })}
        >
          Callable
        </div>
        <Handle
          type="source"
          position={sourceHandlePosition}
          isConnectable={isConnectable}
          className={sourceHandleClassName}
          style={{
            visibility: "hidden"
          }}
        />
      </div>
    </>
  );
};

export default memo(CallableNode);
