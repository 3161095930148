import { classNames } from "primereact/utils";
import { Menu } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";
import { memo, useEffect, useRef, useState } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import type { IActionNodeData } from "../../types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectSelectedNode,
  setCopiedAction,
  setSelectedNode
} from "../../flowDesignerSlice";
import { CSSTransition } from "react-transition-group";
import "./AnimatedEntry.css";
import Icon from "../../../../app/components/Icon";
import { groupToSVGKey, tooltipContent } from "../../data";
import { PeakaTooltip } from "@code2io/fe-studio-component-library";
import { useGetFlowDataQuery } from "../../../../app/services/collaboration/collaborationApi";
import { useFlowId } from "../../hooks/useFlowId";

const ActionNode = ({
  id,
  data: { label, onDelete, icon, disabled, actionType, orientation }
}: NodeProps<IActionNodeData>) => {
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);
  const selected = useAppSelector(selectSelectedNode) === id;
  const selectedFlowId = useFlowId();
  const outputRef = useRef<HTMLDivElement>(null);
  const { data } = useGetFlowDataQuery(selectedFlowId ?? "");
  const nodeData = data?.draft.nodes[id]?.data.variables;
  const dispatch = useAppDispatch();
  const menu = useRef<Menu>(null);

  const menuModel: MenuItem[] = [
    {
      icon: "pi pi-copy",
      label: "Copy",
      command: (event) => {
        event.originalEvent.stopPropagation();
        dispatch(setCopiedAction(event.item.data ?? null));
      },
      disabled,
      data: data?.draft.nodes[id] ?? null
    },
    {
      icon: "pi pi-trash",
      label: "Delete",
      command: (event) => {
        event.originalEvent.stopPropagation();
        onDelete(id);
      },
      disabled
    }
  ];

  useEffect(() => {
    setInProp(true);
  }, []);

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <PeakaTooltip
        position="top"
        text={tooltipContent[actionType]}
        target={
          tooltipContent[actionType] &&
          tooltipContent[actionType].length > 0 &&
          nodeRef.current
            ? nodeRef.current
            : undefined
        }
        showDelay={500}
      />
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{ visibility: "hidden" }}
      />
      <CSSTransition
        nodeRef={nodeRef}
        timeout={500}
        in={inProp}
        classNames="animated-entry-node"
      >
        <div
          ref={nodeRef}
          onClick={() => dispatch(setSelectedNode(id))}
          className="w-full h-full relative cursor-pointer"
          data-testid={`flow-action-node-${label}`}
        >
          <div
            className={classNames("node-container", {
              active: selected
            })}
          >
            <PeakaTooltip
              position="right"
              content={
                <div className="p-2 block" style={{ width: "100%" }}>
                  <table>
                    <tbody>
                      {nodeData?.map((item, i) => {
                        return (
                          <tr style={{ fontFamily: "monospace" }} key={i}>
                            <td
                              style={{
                                fontFamily: "monospace",
                                color: "var(--indigo-300)"
                              }}
                            >
                              {item.label}
                            </td>
                            <td>:</td>
                            <td>{item.value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              }
              target={outputRef}
              showDelay={50}
            />
            <div ref={outputRef} className="node-output">
              {nodeData && nodeData.length > 1
                ? `${nodeData[0]?.label}, ...`
                : nodeData?.[0]?.label}
            </div>
            {!icon && <div className="action-node-indicator" />}
            <div className="flex w-full align-items-center justify-content-between px-2">
              <div className="flex gap-1 align-items-center">
                {icon && (
                  <Icon icon={["svg", groupToSVGKey[icon] ?? "code-2-logo"]} />
                )}
                {label}
              </div>
              {actionType !== "end" && (
                <div className="flex align-items-center">
                  <Menu popup ref={menu} model={menuModel} />
                  <i
                    className="pi pi-ellipsis-h text-xs"
                    onClick={(event) => {
                      event.stopPropagation();
                      menu.current?.toggle(event);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
      <Handle
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

export default memo(ActionNode);
