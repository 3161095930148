import ActiveCampaign from "../../assets/svg/activecampaign.svg?react";
import Airtable from "../../assets/svg/airtable.svg?react";
import BigQuery from "../../assets/svg/bigquery.svg?react";
import Bullhorn from "../../assets/svg/bullhorn.svg?react";
import ClickHouse from "../../assets/svg/clickhouse.svg?react";
import Codat from "../../assets/svg/codat.svg?react";
import ChartMogul from "../../assets/svg/chartmogul.svg?react";
import Code2Logo from "../../assets/svg/code-2-logo.svg?react";
import Databricks from "../../assets/svg/databricks.svg?react";
import Elasticsearch from "../../assets/svg/elasticsearch.svg?react";
import Excel365 from "../../assets/svg/excel365.svg?react";
import Firebase from "../../assets/svg/firebase.svg?react";
import FacebookAds from "../../assets/svg/facebook-ads.svg?react";
import Github from "../../assets/images/connection-icons/icon-github.svg?react";
import GoogleAnalytics from "../../assets/svg/google-analytics.svg?react";
import GoogleSheets from "../../assets/svg/google-sheets.svg?react";
import GoogleAds from "../../assets/svg/google_ads.svg?react";
import HouseCallPro from "../../assets/svg/housecall.svg?react";
import HubSpot from "../../assets/svg/hubspot.svg?react";
import Intercom from "../../assets/svg/intercom.svg?react";
import Instagram from "../../assets/svg/instagram.svg?react";
import Jira from "../../assets/svg/atlassian-jira.svg?react";
import LinkedinAds from "../../assets/svg/linkedinads.svg?react";
import MailChimp from "../../assets/svg/mailchimp.svg?react";
import MailChimpMarketing from "../../assets/svg/mailchimp-marketing.svg?react";
import MailChimpTransactional from "../../assets/svg/mailchimp-transactional.svg?react";
import MariaDB from "../../assets/svg/mariadb.svg?react";
import Mixpanel from "../../assets/svg/mixpanel.svg?react";
import MongoDB from "../../assets/svg/mongodb.svg?react";
import MSSQL from "../../assets/svg/mssql.svg?react";
import Mysql from "../../assets/svg/mysql.svg?react";
import Neo4J from "../../assets/svg/neo4j.svg?react";
import Oracle from "../../assets/svg/oracle.svg?react";
import Odoo from "../../assets/svg/odoo.svg?react";
import Postgres from "../../assets/svg/postgres.svg?react";
import ProvetCloud from "../../assets/svg/provet_cloud.svg?react";
import Redshift from "../../assets/svg/redshift.svg?react";
import Salesforce from "../../assets/svg/salesforce.svg?react";
import SAP from "../../assets/svg/sap.svg?react";
import Sendgrid from "../../assets/svg/sendgrid.svg?react";
import ServiceTitan from "../../assets/svg/service-titan.svg?react";
import Segment from "../../assets/svg/segment.svg?react";
import Shopify from "../../assets/svg/shopify.svg?react";
import Snowflake from "../../assets/svg/snowflake.svg?react";
import Slack from "../../assets/svg/slack.svg?react";
import SingleStore from "../../assets/svg/singlestore.svg?react";
import Stripe from "../../assets/svg/stripe.svg?react";
import Twilio from "../../assets/svg/twilio.svg?react";
import Zendesk from "../../assets/svg/zendesk.svg?react";
import OpenAI from "../../assets/svg/open-ai.svg?react";
import Profitwell from "../../assets/svg/profitwell.svg?react";
import TableLogo from "../../assets/svg/table-logo.svg?react";
import BITableLogo from "../../assets/svg/bi-table-logo.svg?react";
import QueryLogo from "../../assets/svg/query-logo.svg?react";
import Quickbooks from "../../assets/svg/quickbooks.svg?react";
import Qdrant from "../../assets/svg/qdrant.svg?react";
import DataRestLogo from "../../assets/svg/data-rest-logo.svg?react";
import AWSRDSLogo from "../../assets/svg/aws-rds.svg?react";
import ZohoCRMLogo from "../../assets/svg/zoho-crm.svg?react";
import DynamicsLogo from "../../assets/svg/dynamics365.svg?react";
import TeradataLogo from "../../assets/svg/teradata.svg?react";
import PipeDriveLogo from "../../assets/svg/pipedrive.svg?react";
import Pinecone from "../../assets/svg/pinecone.svg?react";
import InsiderLogo from "../../assets/svg/insider.svg?react";
import UpstashVector from "../../assets/svg/upstash-vector.svg?react";
import VisaLogo from "../../assets/svg/visa-logo.svg?react";
import MasterCard from "../../assets/svg/mastercard-logo.svg?react";
import AmericanExpress from "../../assets/svg/amex-logo.svg?react";
import Youtube from "../../assets/svg/youtube.svg?react";
import Weaviate from "../../assets/svg/weaviate.svg?react";
import Zoom from "../../assets/svg/zoom.svg?react";
import Freshdesk from "../../assets/svg/freshdesk.svg?react";
import JiraServiceManagement from "../../assets/svg/jira-service-management.svg?react";
import type { SVGIcon } from "./types";

const iconSVGMap: { [key in SVGIcon]: typeof Airtable } = {
  activecampaign: ActiveCampaign,
  airtable: Airtable,
  bullhorn: Bullhorn,
  bigquery: BigQuery,
  peaka_bigquery: BigQuery,
  clickhouse: ClickHouse,
  "code-2-logo": Code2Logo,
  codat: Codat,
  chartmogul: ChartMogul,
  excel365: Excel365,
  dynamics_365: DynamicsLogo,
  elasticsearch: Elasticsearch,
  firebase: Firebase,
  "facebook-ads": FacebookAds,
  github: Github,
  github_access_token: Github,
  "google-analytics": GoogleAnalytics,
  "google-sheets": GoogleSheets,
  "google-ads": GoogleAds,
  "housecall-pro": HouseCallPro,
  hubspot: HubSpot,
  hubspot_access_token: HubSpot,
  intercom: Intercom,
  jira: Jira,
  "jira-service-management": JiraServiceManagement,
  "internal-query": QueryLogo,
  "internal-table": TableLogo,
  "internal-bi-table": BITableLogo,
  instagram: Instagram,
  "linkedin-ads": LinkedinAds,
  mailchimp: MailChimp,
  "mailchimp-marketing": MailChimpMarketing,
  "mailchimp-transactional": MailChimpTransactional,
  mariadb: MariaDB,
  mixpanel: Mixpanel,
  mongodb: MongoDB,
  mssql: MSSQL,
  mysql: Mysql,
  neo4j: Neo4J,
  oracle: Oracle,
  odoo: Odoo,
  postgres: Postgres,
  "provet-cloud": ProvetCloud,
  "quickbooks-online": Quickbooks,
  redshift: Redshift,
  sap: SAP,
  sendgrid: Sendgrid,
  "service-titan": ServiceTitan,
  salesforce: Salesforce,
  shopify: Shopify,
  "single-store": SingleStore,
  slack: Slack,
  snowflake: Snowflake,
  stripe: Stripe,
  "stripe-integration": Stripe,
  segment: Segment,
  twilio: Twilio,
  zendesk: Zendesk,
  openai: OpenAI,
  profitwell: Profitwell,
  qdrant: Qdrant,
  "data-rest-logo": DataRestLogo,
  "aws-rds": AWSRDSLogo,
  "zoho-crm": ZohoCRMLogo,
  teradata: TeradataLogo,
  pipedrive: PipeDriveLogo,
  databricks: Databricks,
  insider: InsiderLogo,
  upstashvector: UpstashVector,
  visa: VisaLogo,
  mastercard: MasterCard,
  amex: AmericanExpress,
  "youtube-analytics": Youtube,
  pinecone: Pinecone,
  weaviate: Weaviate,
  zoom: Zoom,
  freshdesk: Freshdesk
};

export const getIconSVG = (icon: SVGIcon) => {
  return iconSVGMap[icon];
};
