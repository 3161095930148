import { Suspense, lazy, useState } from "react";
import { classNames } from "primereact/utils";
import type { Application } from "../../../features/applications/types";
import type { Invitation } from "../../../features/applications/components/InvitationCards/InvitationCards";
import { useAppDispatch, useAppSelector } from "../../hooks";
import type { TableColumnsFormData } from "../../../features/uiEditor/components/Properties/components/TableColumnSettings";
import styles from "./C2Modal.module.css";
import {
  selectContent,
  selectFooter,
  selectFormData,
  selectHeader,
  selectLoading,
  selectOptions,
  selectVisible,
  setFormData,
  setLoading,
  setVisible
} from "./C2ModalSlice";
import type { IDuplicateAppFormData } from "../../../features/applications/components/DuplicateApp/types";
import type { IEditAppFormData } from "../../../features/applications/components/EditApp/types";
import type { ISendAppFormData } from "../../../features/applications/components/SendCopyApp/types";
import type { ChartColumnsFormData } from "../../../features/uiEditor/components/Properties/components/ChartColumnSettings";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import FormSkeleton from "../Skeletons/FormSkeleton";
import { useParams } from "react-router-dom";
import { AppIdProvider } from "../../utils/appIdContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import PeakaDialog from "../../utils/peakaDialog";

const InvitationCards = lazy(
  () =>
    import(
      "../../../features/applications/components/InvitationCards/InvitationCards"
    )
);

const CreateAppForm = lazy(
  () =>
    import("../../../features/applications/components/CreateApp/CreateAppForm")
);
const CreateAppFooter = lazy(
  () =>
    import(
      "../../../features/applications/components/CreateApp/CreateAppFooter"
    )
);

const UpgradePlanModal = lazy(
  () =>
    import(
      "../../../features/applications/components/UpgradePlan/UpgradePlanModal"
    )
);
const UpgradePlanFooter = lazy(
  () =>
    import(
      "../../../features/applications/components/UpgradePlan/UpgradePlanFooter"
    )
);

const DuplicateAppForm = lazy(
  () =>
    import(
      "../../../features/applications/components/DuplicateApp/DuplicateAppForm"
    )
);
const DuplicateAppFooter = lazy(
  () =>
    import(
      "../../../features/applications/components/DuplicateApp/DuplicateAppFooter"
    )
);

const EditAppForm = lazy(
  () => import("../../../features/applications/components/EditApp/EditAppForm")
);
const EditAppFooter = lazy(
  () =>
    import("../../../features/applications/components/EditApp/EditAppFooter")
);

const SendCopyAppForm = lazy(
  () =>
    import(
      "../../../features/applications/components/SendCopyApp/SendCopyAppForm"
    )
);
const SendCopyAppFooter = lazy(
  () =>
    import(
      "../../../features/applications/components/SendCopyApp/SendCopyAppFooter"
    )
);

const ShareAppForm = lazy(
  () =>
    import("../../../features/applications/components/ShareApp/ShareAppForm")
);
const ShareAppFooter = lazy(
  () =>
    import("../../../features/applications/components/ShareApp/ShareAppFooter")
);

const TableColumnSettings = lazy(
  () =>
    import(
      "../../../features/uiEditor/components/Properties/components/TableColumnSettings"
    )
);
const TableColumnSettingsFooter = lazy(
  () =>
    import(
      "../../../features/uiEditor/components/Properties/components/TableColumnSettings/TableColumnSettingsFooter"
    )
);

const AllConnections = lazy(
  () =>
    import(
      "../../../features/connections/components/AllConnections/AllConnections"
    )
);

const ActionizeFlowForm = lazy(
  () =>
    import(
      "../../../features/applications/components/ActionizeFlow/ActionizeFlowForm"
    )
);
const ActionizeFlowFooter = lazy(
  () =>
    import(
      "../../../features/applications/components/ActionizeFlow/ActionizeFlowFooter"
    )
);

const ChartColumnSettings = lazy(
  () =>
    import(
      "../../../features/uiEditor/components/Properties/components/ChartColumnSettings"
    )
);
const ChartColumnSettingsFooter = lazy(
  () =>
    import(
      "../../../features/uiEditor/components/Properties/components/ChartColumnSettings/ChartColumnSettingsFooter"
    )
);
const PublishTemplateForm = lazy(
  () =>
    import(
      "../../../features/applications/components/PublishTemplate/PublishTemplateForm"
    )
);

const PublishTemplateFooter = lazy(
  () =>
    import(
      "../../../features/applications/components/PublishTemplate/PublishTemplateFooter"
    )
);

const PreviewOnlyWarningContent = lazy(
  () =>
    import(
      "../../../features/applications/components/PreviewOnlyWarning/PreviewOnlyWarningContent"
    )
);

const C2Modal = () => {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(selectVisible);
  const content = useAppSelector(selectContent);
  const header = useAppSelector(selectHeader);
  const footer = useAppSelector(selectFooter);
  const options = useAppSelector(selectOptions);
  const formData = useAppSelector(selectFormData);
  const loading = useAppSelector(selectLoading);
  const [isEditFormValid, setIsEditFormValid] = useState(true);
  const { appId } = useParams();

  const formChanged = (e: unknown) => {
    dispatch(setFormData(e));
  };

  const loadingChanged = (e: boolean) => {
    dispatch(setLoading(e));
  };

  const onHide = () => {
    dispatch(setVisible(false));
    dispatch(setFormData(null));
  };

  const dialogContent = () => {
    switch (content.component) {
      case "CreateAppForm":
        return (
          <Suspense fallback={<FormSkeleton />}>
            <CreateAppForm
              onChange={(e) => formChanged(e)}
              onLoadingChange={(e) => loadingChanged(e)}
            />
          </Suspense>
        );
      case "EditAppForm":
        return (
          <Suspense fallback={<FormSkeleton />}>
            <EditAppForm
              applicationId={content.props?.applicationId as string}
              onChange={(e) => formChanged(e)}
              name={content.props?.name as string}
              isValid={isEditFormValid}
              onFormValidation={(isValid) => {
                setIsEditFormValid(isValid);
              }}
            />
          </Suspense>
        );
      case "TableColumnSettings":
        return (
          <Suspense fallback={<FormSkeleton count={3} />}>
            <TableColumnSettings onChange={(e) => formChanged(e)} />
          </Suspense>
        );
      case "ChartColumnSettings":
        return (
          <Suspense fallback={<FormSkeleton count={5} />}>
            <ChartColumnSettings onChange={(e) => formChanged(e)} />
          </Suspense>
        );
      case "DuplicateAppForm":
        return (
          <Suspense fallback={<FormSkeleton count={2} />}>
            <DuplicateAppForm
              application={content.props?.application as Application}
              onChange={(e) => formChanged(e)}
            />
          </Suspense>
        );
      case "SendCopyAppForm":
        return (
          <Suspense fallback={<FormSkeleton count={2} />}>
            <SendCopyAppForm
              application={content.props?.application as Application}
              onChange={(e) => formChanged(e)}
            />
          </Suspense>
        );
      case "ShareAppForm":
        return (
          <Suspense fallback={<FormSkeleton count={2} />}>
            <ShareAppForm
              application={content.props?.application as Application}
              onChange={(e) => formChanged(e)}
            />
          </Suspense>
        );
      case "Invitations":
        return (
          <Suspense fallback={<FormSkeleton count={5} />}>
            <InvitationCards
              invitations={content.props?.invitations as Invitation[]}
              type={content.props?.type as string}
            />
          </Suspense>
        );
      case "AllConnections":
        return (
          <Suspense fallback={null}>
            <AllConnections showOnlyDataCatalogSupportedConnections={false} />
          </Suspense>
        );
      case "ActionizeFlowForm":
        return (
          <Suspense fallback={null}>
            <ActionizeFlowForm flowId={content.props?.flowId as string} />
          </Suspense>
        );
      case "PublishTemplateForm":
        return (
          <Suspense fallback={null}>
            <PublishTemplateForm
              application={content.props?.application as Application}
              onChange={(e) => formChanged(e)}
              onLoadingChange={(e) => loadingChanged(e)}
            />
          </Suspense>
        );
      case "PreviewOnlyWarningContent":
        return (
          <Suspense fallback={null}>
            <PreviewOnlyWarningContent
              previewURL={content.props?.previewURL as string}
            />
          </Suspense>
        );
      case "UpgradePlanModal":
        return (
          <Suspense fallback={<FormSkeleton />}>
            <UpgradePlanModal title="" />
          </Suspense>
        );
      default:
        return <div>No Content</div>;
    }
  };

  const footerContent = () => {
    switch (footer) {
      case "CreateAppFooter":
        return (
          <Suspense fallback={null}>
            <CreateAppFooter />
          </Suspense>
        );
      case "EditAppFooter":
        return (
          <Suspense fallback={null}>
            <EditAppFooter
              formData={formData as IEditAppFormData}
              onFormValidation={(isValid) => {
                setIsEditFormValid(isValid);
              }}
              onHide={() => onHide()}
            />
          </Suspense>
        );
      case "TableColumnSettingsFooter":
        return (
          <Suspense fallback={null}>
            <TableColumnSettingsFooter
              formData={formData as TableColumnsFormData}
            />
          </Suspense>
        );
      case "ChartColumnSettingsFooter":
        return (
          <Suspense fallback={null}>
            <ChartColumnSettingsFooter
              formData={formData as ChartColumnsFormData}
            />
          </Suspense>
        );
      case "DuplicateAppFooter":
        return (
          <Suspense fallback={null}>
            <DuplicateAppFooter
              formData={formData as IDuplicateAppFormData}
              onHide={() => onHide()}
            />
          </Suspense>
        );
      case "SendCopyAppFooter":
        return (
          <Suspense fallback={null}>
            <SendCopyAppFooter
              formData={formData as ISendAppFormData}
              onHide={() => onHide()}
            />
          </Suspense>
        );
      case "ShareAppFooter":
        return (
          <Suspense fallback={null}>
            <ShareAppFooter onHide={() => onHide()} />
          </Suspense>
        );
      case "ActionizeFlowFooter":
        return (
          <Suspense fallback={null}>
            <ActionizeFlowFooter />
          </Suspense>
        );
      case "PublishTemplateFooter":
        return (
          <Suspense fallback={null}>
            <PublishTemplateFooter />
          </Suspense>
        );
      case "UpgradePlanFooter":
        return (
          <Suspense fallback={null}>
            <UpgradePlanFooter />
          </Suspense>
        );
      case "none":
        return null;
      default:
        return <div>No Footer</div>;
    }
  };

  return (
    <>
      <AppIdProvider value={appId ? appId : ""}>
        <BlockUI
          fullScreen
          blocked={loading}
          template={<ProgressSpinner animationDuration=".5s" />}
        />
        <PeakaDialog
          className={classNames("border-none", styles.c2Modal)}
          size={options?.size as "xs" | "sm" | "md" | "lg" | "xl" | undefined}
          draggable={false}
          closeIcon={<FontAwesomeIcon width={32} height={32} icon={faXmark} />}
          closeOnEscape={!loading}
          closable={!loading}
          header={header}
          visible={visible}
          headerStyle={header === "none" ? { display: "none" } : {}}
          footer={footerContent()}
          content={dialogContent()}
          style={{
            ...(content.props?.style as {
              [key: string]: string | number | boolean;
            })
          }}
          onHide={() => onHide()}
          appendTo="self"
          pt={{
            content: {
              style:
                options?.defaultPadding === false
                  ? {
                      padding: 0
                    }
                  : {}
            },
            closeButton: () => classNames(styles.modalCloseButton)
          }}
          contentStyle={{
            ...(!footerContent()
              ? {
                  borderBottomLeftRadius: "0.75rem",
                  borderBottomRightRadius: "0.75rem",
                  height: "100%",
                  overflow: "auto"
                }
              : {}),
            ...(header === "none"
              ? {
                  borderTopLeftRadius: "0.75rem",
                  borderTopRightRadius: "0.75rem",
                  height: "100%",
                  overflow: "auto"
                }
              : {})
          }}
          contentClassName={content.props?.contentStyle as string}
        />
      </AppIdProvider>
    </>
  );
};

export default C2Modal;
