export const updateSourceMedia = (colorPreference: "light" | "dark"): void => {
  const pictures = document.querySelectorAll("picture");

  pictures.forEach((picture) => {
    const sources: NodeListOf<HTMLSourceElement> = picture.querySelectorAll(`
          source[media*="theme"], 
          source[data-media*="theme"]
        `);

    sources.forEach((source) => {
      // Preserve the source `media` as a data-attribute
      // to be able to switch between preferences
      if (source.media.includes("theme")) {
        source.dataset.media = source.media;
      }

      // If the source element `media` target is the `preference`,
      // override it to 'all' to show
      // or set it to 'none' to hide
      if (source.dataset.media?.includes(colorPreference)) {
        source.media = "all";
      } else if (source.dataset.media) {
        source.media = "none";
      }
    });
  });
};
