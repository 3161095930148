import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

const initialState = {
  theme: localStorage.getItem("peaka-theme") ?? "dark"
};

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      localStorage.setItem("peaka-theme", action.payload);
      state.theme = action.payload;
    }
  }
});

export const { setTheme } = appSettingsSlice.actions;

export const selectTheme = (state: RootState) => state.appSettings.theme;

export default appSettingsSlice.reducer;
