import { classNames } from "primereact/utils";
import {
  parseTourItemBackLabel,
  parseTourItemNextLabel,
  parseTourItemTitle
} from "../utils/frigadeHelpers";
import { PeakaButton } from "@code2io/fe-studio-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";
import { Text, type FrigadeJS } from "@frigade/react";
import PeakaDialog from "../../../utils/peakaDialog";

export interface FrigadeTourItemDialogProps {
  className?: string;
  frigadeTourItemId: string;
  step: FrigadeJS.FlowStep;
}

const FrigadeTourItemDialog = ({
  className,
  frigadeTourItemId,
  step
}: FrigadeTourItemDialogProps) => {
  const parsedBackLabel = parseTourItemBackLabel(step.secondaryButton?.title);
  const parsedNextLabel = parseTourItemNextLabel(step.primaryButton?.title);
  const parsedTitle = parseTourItemTitle(step.title);

  return (
    <PeakaDialog
      key={frigadeTourItemId}
      className={classNames("", className)}
      footer={
        <div
          className={classNames("flex", {
            "justify-content-between":
              parsedBackLabel.originalValue != null && step.order !== 0,
            "justify-content-end":
              parsedBackLabel.originalValue == null || step.order === 0
          })}
        >
          {parsedBackLabel.originalValue != null && step.order !== 0 && (
            <PeakaButton
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={async () => {
                await step.flow.back();
              }}
              label={parsedBackLabel.value}
              severity="secondary"
              type="button"
            />
          )}
          {parsedNextLabel.originalValue != null && (
            <PeakaButton
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              iconPosition="right"
              onClick={async () => {
                if (step.flow.steps.size === step.order + 1) {
                  await step.flow.complete();
                }
                await step.complete();
              }}
              label={parsedNextLabel.value}
              type="button"
            />
          )}
        </div>
      }
      header={parsedTitle.value}
      onHide={async () => {
        await step.flow.skip();
      }}
      contentClassName="frigade-tour-item-dialog-content"
      visible={step.order === step.flow.getCurrentStepOrder()}
      content={<Text.Body2>{step.subtitle}</Text.Body2>}
    />
  );
};

export default FrigadeTourItemDialog;
